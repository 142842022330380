
/* This is pretty good, don't screw with this.  */
.image-gallery-image {
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 2vw;
    margin-right: 2vw;
    height: 60vh; /* Was 75 for the photo gallery. Leave room for the description*/
}

.image-gallery-image img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    display: block;
    margin: auto;

    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.image-gallery-slide {
    margin-top: 4vh;
}
