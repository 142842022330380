

.formWrapper {
    width: auto;
    height: auto;
}

.googleForm {
    margin: auto;
    display: block;
    width: 100%;
}

