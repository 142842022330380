.app {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #333;
}

img {
    overflow: hidden;    
}

.std {
    position: absolute;
    left: 0;
    top: 50%;
    text-align: center;
}

.std * {
    font-weight: bold;
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    color: white;
}

.std h1 {
    font-size: calc(16px + 4vw);
}
.std p, .countdown {
    font-size: calc(8px + 2vw);
    background-color: transparent;
    border: 0px;
    padding: 0px;
}