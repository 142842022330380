

.navbar {
    margin-bottom: 0;
    border-radius: 0px;
    background-color: #030b1f;
    border: 0;
}

.navbar * {
    color: white !important;
}

.navbar-brand {
    font-family: 'Great Vibes', cursive;
    font-size: 2em;
}
