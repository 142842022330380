/* This is pretty good, don't screw with this.  */
.contacts-image-wrapper {
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 2vw;
    margin-right: 2vw;
    height: 20vh;
}

.static-page-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    display: block;
    margin: auto;

    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.contacts-header {
    text-align: center;
    font-size: 2vh;
    height: 5%;
}

.contacts-list {
    text-align: center;
    font-size: 2vh;
    margin-left: 1vw;
    margin-right: 1vw;
}

p {
    margin: 0px;
}