body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #e1fbff; */
}

* {
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.navbar {
    margin-bottom: 0;
    border-radius: 0px;
    background-color: #030b1f;
    border: 0;
}

.navbar * {
    color: white !important;
}

.navbar-brand {
    font-family: 'Great Vibes', cursive;
    font-size: 2em;
}

.app {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #333;
}

img {
    overflow: hidden;    
}

.std {
    position: absolute;
    left: 0;
    top: 50%;
    text-align: center;
}

.std * {
    font-weight: bold;
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    color: white;
}

.std h1 {
    font-size: calc(16px + 4vw);
}
.std p, .countdown {
    font-size: calc(8px + 2vw);
    background-color: transparent;
    border: 0px;
    padding: 0px;
}

/* This is pretty good, don't screw with this.  */
.image-gallery-image {
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 2vw;
    margin-right: 2vw;
    height: 75vh;
}

.image-gallery-image img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    display: block;
    margin: auto;

    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.image-text-header {
    text-align: center;
    font-size: 2vh;
    height: 5%;
}

.image-text {
    font-size: 2vh;
    margin-left: 1vw;
    margin-right: 1vw;
}

/* For matching gallery to background color if we need it.. */
.image-gallery-slide {
    margin-top: 4vh;
}


/* This is pretty good, don't screw with this.  */
.image-gallery-image {
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 2vw;
    margin-right: 2vw;
    height: 60vh; /* Was 75 for the photo gallery. Leave room for the description*/
}

.image-gallery-image img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    display: block;
    margin: auto;

    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.image-gallery-slide {
    margin-top: 4vh;
}


/* -moz-transform: scale(1.3); for Firefox, default 1*/
/* zoom:130%; For Chrome, IE, default 100%*/

.rsvp-div-browser {

    /* Dont scale firefox separately. zoom seems to work? */
    /* -moz-transform: scale(1.05); */
    zoom:130%; 
}


.formWrapper {
    width: auto;
    height: auto;
}

.googleForm {
    margin: auto;
    display: block;
    width: 100%;
}


/* This is pretty good, don't screw with this.  */
.contacts-image-wrapper {
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 2vw;
    margin-right: 2vw;
    height: 20vh;
}

.static-page-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    display: block;
    margin: auto;

    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.contacts-header {
    text-align: center;
    font-size: 2vh;
    height: 5%;
}

.contacts-list {
    text-align: center;
    font-size: 2vh;
    margin-left: 1vw;
    margin-right: 1vw;
}

p {
    margin: 0px;
}
/* This is pretty good, don't screw with this.  */
.accommodations-image-wrapper {
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 2vw;
    margin-right: 2vw;
    height: 50vh;
}

.static-page-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    display: block;
    margin: auto;

    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.accommodations-details {
    text-align: center;
    font-size: 2vh;
    height: 5%;
}

/* This is pretty good, don't screw with this.  */
.wedding-day-image-wrapper {
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 2vw;
    margin-right: 2vw;
    height: 50vh;
}

.static-page-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    display: block;
    margin: auto;

    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.day-details {
    text-align: center;
    font-size: 2vh;
    height: 5%;
}



/* This is pretty good, don't screw with this.  */
.invitation-image-wrapper {
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 2vw;
    margin-right: 2vw;
    height: 90vh;
}

.static-page-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    display: block;
    margin: auto;

    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}


/* This is pretty good, don't screw with this.  */
.image-gallery-image {
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 2vw;
    margin-right: 2vw;
    height: 75vh;
}

.image-gallery-image img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    display: block;
    margin: auto;

    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.image-text-header {
    text-align: center;
    font-size: 2vh;
    height: 5%;
}

.image-text {
    font-size: 2vh;
    margin-left: 1vw;
    margin-right: 1vw;
}

/* For matching gallery to background color if we need it.. */
.image-gallery-slide {
    margin-top: 4vh;
}

